import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { jobListings } from '../data/jobListings';
import BackgroundShapes from '../components/BackgroundShapes';

const JobDetailPage = () => {
    const { id } = useParams();
    const job = jobListings.find(job => job.id === parseInt(id));

    const [time, setTime] = useState(() => {
        const now = new Date();
        return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    });

    // Check if deadline has passed
    const isDeadlinePassed = () => {
        if (!job || !job.deadline || job.isOpenApplication) return false;

        const today = new Date();
        const deadline = new Date(job.deadline);
        return today > deadline;
    };

    const deadlinePassed = isDeadlinePassed();

    // Update time every second
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setTime(`${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    if (!job) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <p>Job not found. <Link to="/jobs" className="text-blue-500">Return to listings</Link></p>
            </div>
        );
    }

    return (
        <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#f8f9fc] to-[#f4f6f9]">
            <BackgroundShapes />

            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-10">
                {/* Top navigation */}
                <div className="flex items-center mb-16">
                    <Link
                        to="/jobs"
                        className="text-dark-text hover:text-[#8b6afe] text-2xl uppercase tracking-widest transition-colors duration-300 cursor-pointer"
                    >
                        Opportunities
                    </Link>

                    <div className="flex-grow h-px bg-dark-text/20 mx-4 md:mx-8"></div>

                    <p className="text-dark-text text-xl sm:text-2xl uppercase tracking-widest time-pulse">
                        {time}
                    </p>
                </div>

                {/* Job details */}
                <div className="bg-white rounded-lg shadow-md p-6 md:p-8 mb-12">
                    <div className="mb-6">
                        <h1 className="text-3xl md:text-4xl text-secondary font-bold mb-2">{job.title}</h1>
                        <div className="flex flex-wrap items-center gap-4 text-gray-600 mt-4">
                            <div className="flex items-center">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
                                </svg>
                                <span>{job.location}</span>
                            </div>
                            <div className="flex items-center">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                                </svg>
                                <span>{job.term}</span>
                            </div>
                            {job.paid && (
                                <div className="flex items-center ">
                                    <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path>
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd"></path>
                                    </svg>
                                    <span>Paid Position</span>
                                </div>
                            )}
                            <div className="flex items-center text-red-500">
                                <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path>
                                </svg>
                                <span>{job.isOpenApplication ? 'Always Open' : `Application Deadline: ${job.deadline}`}</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-8">
                        <h2 className="text-xl font-semibold mb-3">Our Vision</h2>
                        <p className="text-gray-700 mb-6">{job.vision}</p>

                        <h2 className="text-xl font-semibold mb-3">The Role</h2>
                        <p className="text-gray-700 mb-6">{job.roleDescription}</p>

                        <div className="mb-6">
                            <h2 className="text-xl font-semibold mb-3">Key Responsibilities</h2>
                            <ul className="list-disc pl-5 space-y-1">
                                {job.responsibilities.map((item, index) => (
                                    <li key={index} className="text-gray-700">{item}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="text-xl font-semibold mb-3">Required Qualifications</h2>
                            <ul className="list-disc pl-5 space-y-1">
                                {job.required.map((item, index) => (
                                    <li key={index} className="text-gray-700">{item}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="text-xl font-semibold mb-3">Preferred Qualifications</h2>
                            <ul className="list-disc pl-5 space-y-1">
                                {job.preferred.map((item, index) => (
                                    <li key={index} className="text-gray-700">{item}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="mb-6">
                            <h2 className="text-xl font-semibold mb-3">Why Join Us?</h2>
                            <ul className="list-disc pl-5 space-y-1">
                                {job.benefits.map((item, index) => (
                                    <li key={index} className="text-gray-700">{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="h-px bg-gray-200 my-8"></div>

                    {/* Application Instructions */}
                    {job.isOpenApplication ? (
                        <div id="application-instructions" className="bg-[#f8f9fc] border border-[#e4e7ec] rounded-lg p-6">
                            <h2 className="text-2xl font-bold mb-4">To Apply</h2>

                            <p className="text-gray-700 mb-4">Please send your:</p>

                            <ul className="list-disc pl-5 space-y-2 mb-6">
                                <li className="text-gray-700">Resume/CV</li>
                                <li className="text-gray-700">Cover letter explaining:
                                    <ul className="list-disc pl-5 space-y-1 mt-1">
                                        <li className="text-gray-700">What type of role you're interested in</li>
                                        <li className="text-gray-700">How your skills align with our mission</li>
                                        <li className="text-gray-700">Your relevant experience and background</li>
                                    </ul>
                                </li>
                                <li className="text-gray-700">Any additional materials that showcase your expertise</li>
                            </ul>

                            <p className="text-gray-700 mb-6">
                                to: <a href="mailto:hello@kaiolabs.com" className="text-[#6942fe] hover:underline font-medium">hello@kaiolabs.com</a>
                            </p>

                            <a
                                href={`mailto:hello@kaiolabs.com?subject=Open Application`}
                                className="inline-block px-6 py-3 bg-gradient-to-r from-[#6942fe] to-[#33aa63] text-white font-semibold rounded-md hover:opacity-90 transition-opacity"
                            >
                                Submit Open Application
                            </a>
                        </div>
                    ) : (
                        <div id="application-instructions" className="bg-[#f8f9fc] border border-[#e4e7ec] rounded-lg p-6">
                            <h2 className="text-2xl font-bold mb-4">To Apply</h2>

                            {deadlinePassed ? (
                                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-4">
                                    <p className="font-semibold">Application Deadline Passed</p>
                                    <p>The application period for this position has closed. Please check our other open positions.</p>
                                </div>
                            ) : (
                                <>
                                    <p className="text-gray-700 mb-4">Please send us your:</p>

                                    <ul className="list-disc pl-5 space-y-2 mb-6">
                                        <li className="text-gray-700">Resume/CV</li>
                                        <li className="text-gray-700">Academic transcript (unofficial is acceptable)</li>
                                        <li className="text-gray-700">A few words explaining your interest in clean energy and relevant experiences (200 words max)</li>
                                    </ul>

                                    {/* <p className="text-gray-700 mb-6">
                                        to: <a href="mailto:hello@kaiolabs.com" className="text-[#6942fe] hover:underline font-medium">hello@kaiolabs.com</a>
                                    </p> */}

                                    <a
                                        href={`mailto:hello@kaiolabs.com?subject=Application: ${job.title}`}
                                        className="inline-block px-6 py-3 bg-gradient-to-r from-[#6942fe] to-[#33aa63] text-white font-semibold rounded-md hover:opacity-90 transition-opacity"
                                    >
                                        Apply via Email
                                    </a>
                                </>
                            )}

                            <p className="text-sm text-gray-600 mt-6 italic">
                                We are an equal opportunity employer and value diversity. We do not discriminate on the basis of race, religion, color, national origin, gender, sexual orientation, age, marital status, veteran status, or disability status.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobDetailPage;