// src/data/jobListings.js
export const jobListings = [
    {
        id: 1,
        title: "Research Intern - Electrochemistry",
        location: "Newark, NJ",
        term: "Summer Internship (10 weeks, May-August 2025)",
        deadline: "March 21, 2025",
        paid: true,
        isOpen: false,
        desiredMajors: ["Chemistry", "Chemical Engineering", "Mechanical Engineering", "Material Science"],
        intro: "We're a dynamic startup on a mission to make sustainable fuels and chemicals economically viable. If you're a motivated student with a passion for clean energy and chemistry, we'd love to hear from you.",
        vision: "We're a pioneering deep-tech startup with a laser focus: making sustainable fuels and chemicals economically viable. Our innovative approach combines machine learning with autonomous experimentation to accelerate scientific breakthroughs in clean energy.",
        roleDescription: "This internship offers a unique opportunity to gain hands-on experience in cutting-edge clean energy research. You'll work directly with our founding electrochemist, who brings extensive experience from their PhD and postdoctoral research.",
        responsibilities: [
            "Learn advanced laboratory techniques in electrochemistry",
            "Contribute to meaningful research in sustainable energy",
            "Gain exposure to the fast-paced environment of a deep-tech startup",
            "Assist in material synthesis and preparation",
            "Perform analysis of experimental results",
            "Document experimental procedures and results",
            "Collaborate with our scientific team on ongoing research projects"
        ],
        required: [
            "Currently enrolled full-time as an undergraduate or master's student",
            "Studying Chemistry, Chemical Engineering, Mechanical Engineering, Materials Science, or related fields",
            "Strong academic record and laboratory coursework",
            "Excellent attention to detail and documentation skills",
            "Ability to work safely and carefully in a laboratory environment"
        ],
        preferred: [
            "Previous laboratory research experience",
            "Coursework or experience in electrochemistry",
            "Experience with analytical instruments"
        ],
        benefits: [
            "Gain hands-on experience with cutting-edge clean energy research",
            "Work alongside and learn from experienced scientists",
            "Build valuable skills in a rapidly growing field",
            "Be part of a well-funded startup addressing climate change",
            "Potential for future opportunities with our growing team"
        ],
        applicationEmail: "hello@kaiolabs.com"
    },
    {
        id: 2,
        title: "Research Intern - Data Science",
        location: "Newark, NJ",
        term: "Summer Internship (10 weeks, May-August 2025)",
        deadline: "March 21, 2025",
        paid: true,
        isOpen: false,
        desiredMajors: ["Computer Science", "Data Science", "Statistics", "Applied Mathematics", "Physics"],
        intro: "Join our innovative team developing next-generation processes for sustainable chemical production. This internship is perfect for students passionate about applying data science to climate challenges while gaining hands-on experience at the intersection of AI and chemistry.",
        vision: "Our pioneering deep-tech startup combines machine learning with autonomous experimentation to accelerate scientific breakthroughs in clean energy. Founded by a team of scientists and engineers from Cambridge University and ETH Zurich with expertise in chemistry, machine learning, and robotics, we're bridging the gap between AI, automation, and materials discovery.",
        roleDescription: "Work directly with our CEO to leverage public datasets for accelerating materials discovery. You'll explore how data science can inform our experimental strategy by analyzing large materials science databases, developing feature representations for material properties, and contributing to our machine learning pipeline for experimental optimization.",
        responsibilities: [
            "Analyze and process data from the large public datasets",
            "Develop and implement feature engineering strategies for materials data",
            "Create visualizations to communicate findings effectively",
            "Curate a dataset of promising materials candidates",
            "Prepare a comprehensive report on feature importance",
            "Collaborate with our scientific team to bridge data science and chemistry"
        ],
        required: [
            "Currently enrolled full-time as an undergraduate or master's student",
            "Studying Computer Science, Data Science, Data Engineering, Statistics, Applied Mathematics, Physics, or related fields",
            "Strong programming skills in Python",
            "Experience with PyTorch, NumPy, Pandas, and data visualization libraries",
            "Genuine interest in applying data science to chemistry/materials science",
            "Excellent documentation and communication skills"
        ],
        preferred: [
            "Experience with version control (Git) and knowledge of CI/CD practices",
            "Familiarity with materials science or chemistry databases",
            "Experience with feature engineering or dimensionality reduction techniques"
        ],
        benefits: [
            "Work directly with our CEO, bringing expertise from Cambridge in chemistry and machine learning",
            "Apply cutting-edge data science to meaningful climate impact",
            "Gain unique experience bridging AI and materials science",
            "Be part of a well-funded startup tackling climate change",
            "Potential for future opportunities as we grow"
        ],
        applicationEmail: "hello@kaiolabs.com"
    },
    {
        id: 3,
        title: "Open Application",
        location: "Remote / Newark, NJ",
        term: "Full-time, Part-time, or Internship",
        deadline: "Ongoing",
        paid: true,
        desiredMajors: ["All relevant fields"],
        intro: "Don't see a position that matches your skills, but excited about our mission? We're always looking for exceptional talent to join our team. Submit an open application and tell us how you can contribute to our vision.",
        applicationEmail: "hello@kaiolabs.com",
        isOpenApplication: true
    }
];