import React from 'react';

const BackgroundShapes = () => {
    return (
        <>
            {/* Top right blob */}
            <div
                className="absolute top-0 right-0 -rotate-[127deg] opacity-80 z-0"
                style={{
                    width: '40%',
                    height: '40%',
                    transform: 'translate(20%, -30%) rotate(-127deg)'
                }}
            >
                <div
                    className="w-full h-full opacity-80"
                    style={{
                        backgroundImage: `url('/assets/media/purple_splash.png')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                ></div>
            </div>

            {/* Middle left blob */}
            <div
                className="absolute left-0 top-1/3 opacity-80 z-0"
                style={{
                    width: '70%',
                    height: '50%',
                    transform: 'translate(-20%, -50%)'
                }}
            >
                <div
                    className="w-full h-full opacity-80"
                    style={{
                        backgroundImage: `url('/assets/media/blue_green_splash.png')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                ></div>
            </div>

            {/* Bottom blob */}
            <div
                className="absolute bottom-0 left-1/4 opacity-80 z-0 rotate-[138deg]"
                style={{
                    width: '100%',
                    height: '50%',
                    transform: 'translate(0%, 30%) rotate(138deg)'
                }}
            >
                <div
                    className="w-full h-full opacity-80"
                    style={{
                        backgroundImage: `url('/assets/media/purple_splash.png')`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                ></div>
            </div>
        </>
    );
};

export default BackgroundShapes;