import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BackgroundShapes from '../components/BackgroundShapes';

const AboutPage = () => {
    // Get current time in format HH:MM:SS
    const [time, setTime] = useState(() => {
        const now = new Date();
        return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    });

    // Update time every second
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setTime(`${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    // Core values
    const coreValues = [
        {
            id: 1,
            title: "Repeatability",
            description: "Consistent, error-free results through automation"
        },
        {
            id: 2,
            title: "Adaptability",
            description: "Compatible with any source of CO₂ and reaction agnostic"
        },
        {
            id: 3,
            title: "Scalability",
            description: "Architecture miniaturizes industrial conditions"
        }
    ];

    return (
        <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#f8f9fc] to-[#f4f6f9]">
            <BackgroundShapes />

            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-10">
                {/* Top header with Navigation and Time */}
                <div className="flex items-center mb-16">
                    <Link
                        to="/"
                        className="text-dark-text hover:text-[#8b6afe] text-2xl uppercase tracking-widest transition-colors duration-300 cursor-pointer opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '100ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        Home
                    </Link>

                    {/* Horizontal line that spans the space between Navigation and Time */}
                    <div
                        className="flex-grow h-px bg-dark-text/20 mx-4 md:mx-8 opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '250ms',
                            animationFillMode: 'forwards'
                        }}
                    ></div>

                    <p
                        className="text-dark-text text-xl sm:text-2xl uppercase tracking-widest time-pulse opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '300ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        {time}
                    </p>
                </div>

                {/* Main content */}
                <div className="mt-16 md:mt-24">
                    {/* About heading */}
                    <div className="mb-16">
                        <h1 className="poppins-bold text-dark-text text-6xl sm:text-8xl hover:text-secondary transition-colors duration-300 opacity-0 transform translate-x-4"
                            style={{
                                animation: 'slideInRight 0.8s ease-out forwards',
                                animationDelay: '400ms',
                                animationFillMode: 'forwards'
                            }}>
                            About
                        </h1>
                        <p className="text-dark-text italic text-lg md:text-xl mt-4 max-w-2xl opacity-0 transform translate-y-4"
                            style={{
                                animation: 'fadeIn 1s ease-out forwards',
                                animationDelay: '800ms',
                                animationFillMode: 'forwards'
                            }}>
                            From CO₂ to value
                        </p>
                    </div>

                    {/* Vision and Mission Section */}
                    <div className="mb-20 opacity-0 transform translate-y-4"
                        style={{
                            animation: 'fadeIn 1s ease-out forwards',
                            animationDelay: '600ms',
                            animationFillMode: 'forwards'
                        }}>
                        <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8">
                            <h2 className="text-secondary font-bold text-2xl mb-6">Our Mission</h2>
                            <p className="text-dark-text text-lg mb-6 leading-relaxed">
                                The climate emergency demands a fundamental re-imagining of our fossil fuel-dependent economy.
                                At kaıolabs, we envision a world where carbon dioxide is transformed from a waste product into
                                valuable fuels and chemicals, creating a sustainable circular carbon economy.
                            </p>
                            <p className="text-dark-text text-lg mb-6 leading-relaxed">
                                Our mission is to revolutionize the development of electrolyzers -
                                reactors which use renewable electricity to convert CO₂ into high-value products -
                                through AI-powered automation.
                                By combining expertise in artificial intelligence, robotics, and electrochemistry,
                                we're accelerating the discovery of groundbreaking catalytic technologies that make this
                                conversion process more efficient, selective, and economically viable.
                            </p>
                            <p className="text-dark-text text-lg leading-relaxed">
                                This approach not only promises to make sustainable processes economically viable but also opens
                                new frontiers in clean energy storage — transforming what was once considered waste into valuable products.
                                This is what we mean by <span className="font-bold text-secondary">From CO₂ to value</span>.
                            </p>
                        </div>
                    </div>

                    {/* Our Technology Section */}
                    <div className="mb-20">
                        <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 opacity-0 transform translate-y-4"
                            style={{
                                animation: 'fadeIn 1s ease-out forwards',
                                animationDelay: '1000ms',
                                animationFillMode: 'forwards'
                            }}>
                            <h2 className="text-secondary font-bold text-2xl mb-6">Our Technology</h2>
                            <p className="text-dark-text text-lg mb-8 leading-relaxed">
                                We're accelerating electrolyzer innovation through a closed-loop, AI-powered platform that dramatically speeds up the development cycle of these reactors.
                                Our system integrates the entire DMTA (Design-Make-Test-Analyze) cycle into a single automated workflow:
                            </p>

                            {/* DMTA Cycle Visualized */}
                            <div className="relative flex flex-col md:flex-row items-center justify-center mb-12">
                                {/* Central connecting element */}
                                <div className="absolute w-32 h-32 md:w-40 md:h-40 rounded-full bg-gradient-to-r from-[#6942fe]/10 to-[#33aa63]/10 z-0 flex items-center justify-center">
                                    <div className="w-24 h-24 md:w-32 md:h-32 rounded-full bg-gradient-to-r from-[#6942fe]/20 to-[#33aa63]/20 flex items-center justify-center">
                                        {/* Improved circular loop animation with fixed timing */}
                                        <svg viewBox="0 0 100 100" width="80" height="80">
                                            <defs>
                                                <linearGradient id="cycleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                                                    <stop offset="0%" stopColor="#6942fe" />
                                                    <stop offset="100%" stopColor="#33aa63" />
                                                </linearGradient>
                                            </defs>
                                            {/* Main circle path */}
                                            <circle
                                                cx="50"
                                                cy="50"
                                                r="36"
                                                fill="none"
                                                stroke="url(#cycleGradient)"
                                                strokeWidth="3"
                                            />

                                            {/* Animated circle marker */}
                                            <circle cx="50" cy="14" r="4" fill="#6942fe">
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 50 50"
                                                    to="360 50 50"
                                                    dur="6s"
                                                    repeatCount="indefinite"
                                                />
                                            </circle>

                                            {/* Small arrow direction indicator */}
                                            <path
                                                d="M50,8 L53,14 L47,14 Z"
                                                fill="#6942fe"
                                                transform="rotate(0, 50, 50)"
                                            >
                                                <animateTransform
                                                    attributeName="transform"
                                                    type="rotate"
                                                    from="0 50 50"
                                                    to="360 50 50"
                                                    dur="6s"
                                                    repeatCount="indefinite"
                                                />
                                            </path>
                                        </svg>
                                    </div>
                                </div>

                                {/* Four quadrants of the DMTA cycle */}
                                <div className="grid grid-cols-2 gap-8 md:gap-16 z-10 mb-8 md:mb-0">
                                    <div className="p-4 md:p-6 bg-white rounded-lg shadow-md flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300">
                                        <div className="w-12 h-12 bg-gradient-to-r from-[#6942fe] to-[#33aa63] rounded-full flex items-center justify-center text-white font-bold text-xl mb-3">
                                            D
                                        </div>
                                        <h3 className="text-secondary font-bold text-xl mb-2">Design</h3>
                                        <p className="text-dark-text text-sm">AI-driven catalyst design with multi-objective optimization</p>
                                    </div>

                                    <div className="p-4 md:p-6 bg-white rounded-lg shadow-md flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300">
                                        <div className="w-12 h-12 bg-gradient-to-r from-[#6942fe] to-[#33aa63] rounded-full flex items-center justify-center text-white font-bold text-xl mb-3">
                                            M
                                        </div>
                                        <h3 className="text-secondary font-bold text-xl mb-2">Make</h3>
                                        <p className="text-dark-text text-sm">Automated robotic synthesis with precision control</p>
                                    </div>

                                    <div className="p-4 md:p-6 bg-white rounded-lg shadow-md flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300">
                                        <div className="w-12 h-12 bg-gradient-to-r from-[#6942fe] to-[#33aa63] rounded-full flex items-center justify-center text-white font-bold text-xl mb-3">
                                            T
                                        </div>
                                        <h3 className="text-secondary font-bold text-xl mb-2">Test</h3>
                                        <p className="text-dark-text text-sm">Parallel experimentation under industry-relevant conditions</p>
                                    </div>

                                    <div className="p-4 md:p-6 bg-white rounded-lg shadow-md flex flex-col items-center text-center transform hover:scale-105 transition-all duration-300">
                                        <div className="w-12 h-12 bg-gradient-to-r from-[#6942fe] to-[#33aa63] rounded-full flex items-center justify-center text-white font-bold text-xl mb-3">
                                            A
                                        </div>
                                        <h3 className="text-secondary font-bold text-xl mb-2">Analyze</h3>
                                        <p className="text-dark-text text-sm">Data-driven insights feeding back into the AI system</p>
                                    </div>
                                </div>
                            </div>

                            <p className="text-center text-[#33aa63] font-bold text-2xl mb-12">40x Faster Development Cycles</p>

                            <h3 className="text-secondary font-bold text-2xl mb-6 text-center">Our Core Values</h3>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                {coreValues.map((value, index) => (
                                    <div key={value.id} className="bg-white p-6 rounded-lg border-l-4 border-[#6942fe] shadow-md transform hover:scale-105 transition-all duration-300">
                                        <h3 className="text-secondary font-bold text-xl mb-2">{value.title}</h3>
                                        <p className="text-dark-text">{value.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Market Opportunity */}
                    <div className="mb-20">
                        <div className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-8 opacity-0 transform translate-y-4"
                            style={{
                                animation: 'fadeIn 1s ease-out forwards',
                                animationDelay: '1800ms',
                                animationFillMode: 'forwards'
                            }}>
                            <h2 className="text-secondary font-bold text-2xl mb-6">Market Opportunity</h2>
                            <p className="text-dark-text text-lg mb-6 leading-relaxed">
                                Our high-performance electrolyzers developed through our Automated Discovery Platform
                                enable the transformation of CO₂ into valuable products like syngas and ethylene—key
                                building blocks for sustainable aviation fuels, maritime fuels, green chemicals, and
                                sustainable polymers.
                            </p>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-secondary font-bold text-xl mb-3">Our Technology Advantage</h3>
                                    <ul className="list-disc pl-5 text-dark-text">
                                        <li>Automated Discovery Platform for rapid electrolyzer development</li>
                                        <li>40x Faster Development through AI-optimization</li>
                                        <li>Validated Performance with clear path to scale</li>
                                    </ul>
                                </div>
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <h3 className="text-secondary font-bold text-xl mb-3">Customer Benefits</h3>
                                    <ul className="list-disc pl-5 text-dark-text">
                                        <li>Cost-Competitive Products with no green premium</li>
                                        <li>Higher Efficiency reducing electricity requirements</li>
                                        <li>Universal Compatibility with any CO₂ source</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Call to action - Meet our team */}
                    <div className="mt-20 flex flex-col items-end text-right opacity-0 transform -translate-x-4"
                        style={{
                            animation: 'slideInLeft 0.8s ease-out forwards',
                            animationDelay: '2100ms',
                            animationFillMode: 'forwards'
                        }}>
                        <h3 className="text-secondary font-bold text-2xl sm:text-3xl uppercase tracking-widest transition-all duration-300 hover:tracking-wider">
                            Meet Our Team
                        </h3>
                        <p className="text-dark-text mt-2 max-w-md ml-auto">
                            Our team unites world-class expertise in AI, robotics, and electrochemistry to tackle climate tech's biggest challenges.
                        </p>
                        <Link
                            to="/team"
                            className="mt-6 inline-block px-6 py-3 bg-gradient-to-r from-[#6942fe] to-[#33aa63] text-white font-semibold rounded-md hover:scale-105 transition-all duration-300"
                        >
                            Our Team
                        </Link>
                    </div>
                </div>
            </div>

            {/* Gradient line at bottom with hover effect */}
            <div className="absolute bottom-16 right-16 hover-expand cursor-pointer transition-all duration-300 opacity-0"
                style={{
                    animation: 'fadeIn 1s ease-out forwards',
                    animationDelay: '2300ms',
                    animationFillMode: 'forwards'
                }}>
                <div className="h-1.5 w-64 gradient-line transform -rotate-90 origin-right"></div>
            </div>
        </div>
    );
};

export default AboutPage;