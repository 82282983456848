import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BackgroundShapes from '../components/BackgroundShapes';

const TeamPage = () => {
    // Get current time in format HH:MM:SS
    const [time, setTime] = useState(() => {
        const now = new Date();
        return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    });

    // Update time every second
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setTime(`${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    // Team members data
    const teamMembers = [
        {
            id: 1,
            name: "Will Purvis",
            title: "Co-Founder",
            bio: [
                "Top chemistry student at St Andrews",
                "Developed ML algorithms for chemistry at world-leading materials discovery lab, University of Toronto",
                "Applied Machine Learning from Cambridge"
            ],
            imgSrc: "/assets/team/will.jpeg", // Add actual image paths
            institutions: [
                { name: "University of Cambridge", logo: "/assets/institutions/cambridge.png" },
                { name: "University of Toronto", logo: "/assets/institutions/toronto.png" },
                { name: "University of St Andrews", logo: "/assets/institutions/standrews.png" }
            ]
        },
        {
            id: 2,
            name: "Yash Sinha",
            title: "Co-Founder",
            bio: [
                "Robotics and automation engineer from ETH Zurich",
                "Designed record-breaking optimisation algorithms for rockets and autonomous racing cars",
                "Expert in custom hardware across various applications"
            ],
            imgSrc: "/assets/team/yash.jpeg",
            institutions: [
                { name: "ETH Zurich", logo: "/assets/institutions/eth.png" },
                { name: "TU Delft", logo: "/assets/institutions/tudelft.png" }
            ]
        },
        {
            id: 3,
            name: "Luisa Barrera",
            title: "Principal Scientist",
            bio: [
                "Material Scientist (MIT)",
                "Mechanical Engineering PhD (UMich)",
                "Post-doc in electrochemistry (GeorgiaTech)"
            ],
            imgSrc: "/assets/team/luisa.jpeg",
            institutions: [
                { name: "Georgia Tech", logo: "/assets/institutions/georgiatech.png" },
                { name: "MIT", logo: "/assets/institutions/mit.png" },
                { name: "University of Michigan", logo: "/assets/institutions/umich.png" }
            ]
        }
    ];

    // Advisors data
    const advisors = [
        {
            id: 1,
            name: "Prof. Seth W. Snyder",
            title: "Chief Advisor",
            bio: "Director of Clean Energy & Transportation at Idaho National Laboratory overseeing $25M budget and 100-person division. Angel investor in clean tech with 18 patents and 3 R&D 100 Awards. Founded successful water treatment startup and serves as Chief Commercialization Officer for Midwest Alliance for Clean Hydrogen. Northwestern Professor with extensive DOE leadership across national labs. 50+ patents in clean technology.",
            imgSrc: "/assets/team/seth.jpeg",
            institutions: [
                { name: "Idaho National Lab", logo: "/assets/institutions/idaho.png" },
                { name: "Argonne National Lab", logo: "/assets/institutions/argonne.png" },
                { name: "Northwestern University", logo: "/assets/institutions/northwestern.png" }
            ]
        },
        {
            id: 2,
            name: "Dr. Tom Burdyny",
            title: "Scientific Advisor",
            bio: "Pioneer in CO₂ electrolysis leading the Burdyny Energy Lab at TU Delft. World-renowned expert in converting carbon dioxide into valuable chemicals using renewable electricity. Engineering innovator specializing in scaling electrochemical technologies for industrial applications. Research focused on revolutionizing fossil fuel-dependent manufacturing with electrocatalysis. 10,000+ citations for groundbreaking work at the intersection of mechanical engineering and electrochemistry.",
            imgSrc: "/assets/team/tom.jpg",
            institutions: [
                { name: "TU Delft", logo: "/assets/institutions/tudelft.png" }
            ]
        }
    ];

    return (
        <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#f8f9fc] to-[#f4f6f9]">
            <BackgroundShapes />

            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-10">
                {/* Top header with Navigation and Time */}
                <div className="flex items-center mb-16">
                    <Link
                        to="/"
                        className="text-dark-text hover:text-[#8b6afe] text-2xl uppercase tracking-widest transition-colors duration-300 cursor-pointer opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '100ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        Home
                    </Link>

                    {/* Horizontal line that spans the space between Contact and Time */}
                    <div
                        className="flex-grow h-px bg-dark-text/20 mx-4 md:mx-8 opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '250ms',
                            animationFillMode: 'forwards'
                        }}
                    ></div>

                    <p
                        className="text-dark-text text-xl sm:text-2xl uppercase tracking-widest time-pulse opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '300ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        {time}
                    </p>
                </div>

                {/* Main content */}
                <div className="mt-16 md:mt-24">
                    {/* Team heading */}
                    <div className="mb-16">
                        <h1 className="poppins-bold text-dark-text text-6xl sm:text-8xl hover:text-secondary transition-colors duration-300 opacity-0 transform translate-x-4"
                            style={{
                                animation: 'slideInRight 0.8s ease-out forwards',
                                animationDelay: '400ms',
                                animationFillMode: 'forwards'
                            }}>
                            Our Team
                        </h1>
                        <p className="text-dark-text italic text-lg md:text-xl mt-4 max-w-2xl opacity-0 transform translate-y-4"
                            style={{
                                animation: 'fadeIn 1s ease-out forwards',
                                animationDelay: '800ms',
                                animationFillMode: 'forwards'
                            }}>
                            World-class expertise in AI, robotics, and electrochemistry united
                        </p>
                    </div>

                    {/* Team members grid */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
                        {teamMembers.map((member, index) => (
                            <div
                                key={member.id}
                                className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-6 transform hover:scale-105 transition-all duration-300 opacity-0 flex flex-col"
                                style={{
                                    animation: 'fadeIn 0.8s ease-out forwards',
                                    animationDelay: `${600 + (index * 150)}ms`,
                                    animationFillMode: 'forwards'
                                }}
                            >
                                <div className="mb-4 h-64 w-full overflow-hidden rounded-md bg-gradient-to-r from-[#6942fe]/10 to-[#33aa63]/10">
                                    <div className="mb-4 h-64 w-full overflow-hidden rounded-md bg-gradient-to-r from-[#6942fe]/10 to-[#33aa63]/10">
                                        <img
                                            src={member.imgSrc}
                                            alt={`${member.name}, ${member.title}`}
                                            className="w-full h-full object-contain object-center"
                                            style={{
                                                objectPosition: member.name === "Will Purvis" || member.name === "Luisa Barrera"
                                                    ? "center 35%"
                                                    : "center center"
                                            }}
                                        />
                                    </div>
                                </div>
                                <h3 className="text-secondary font-bold text-xl">{member.name}</h3>
                                <p className="text-[#6942fe] font-medium text-sm mb-2">{member.title}</p>

                                {/* Bullet point bio */}
                                <ul className="text-dark-text text-sm mb-4 list-disc pl-5">
                                    {member.bio.map((point, i) => (
                                        <li key={i} className="mb-1">{point}</li>
                                    ))}
                                </ul>

                                {/* Add spacer to push logos to bottom */}
                                <div className="flex-grow"></div>

                                {/* Institution logos */}
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {member.institutions.map((institution, i) => (
                                        <div
                                            key={i}
                                            className="w-16 h-16 bg-white rounded-md flex items-center justify-center p-1 shadow-sm"
                                            title={institution.name}
                                        >
                                            {institution.logo ? (
                                                <img
                                                    src={institution.logo}
                                                    alt={institution.name}
                                                    className="max-w-full max-h-full object-contain"
                                                />
                                            ) : (
                                                <span className="text-xs text-gray-400">
                                                    {institution.name.split(' ').map(word => word[0]).join('')}
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Advisors heading */}
                    <div className="mt-20 mb-8">
                        <h2 className="poppins-bold text-dark-text text-4xl sm:text-5xl opacity-0 transform translate-x-4"
                            style={{
                                animation: 'slideInRight 0.8s ease-out forwards',
                                animationDelay: '1000ms',
                                animationFillMode: 'forwards'
                            }}>
                            Advisory Board
                        </h2>
                    </div>

                    {/* Advisors grid - stacked vertically */}
                    <div className="grid grid-cols-1 gap-8 mt-8">
                        {advisors.map((advisor, index) => (
                            <div
                                key={advisor.id}
                                className="bg-white/80 backdrop-blur-sm rounded-lg shadow-lg p-6 transform hover:scale-105 transition-all duration-300 opacity-0"
                                style={{
                                    animation: 'fadeIn 0.8s ease-out forwards',
                                    animationDelay: `${1100 + (index * 150)}ms`,
                                    animationFillMode: 'forwards'
                                }}
                            >
                                <div className="flex flex-col md:flex-row gap-6">
                                    <div className="w-full md:w-64 h-64 overflow-hidden rounded-md bg-gradient-to-r from-[#6942fe]/10 to-[#33aa63]/10 flex-shrink-0">
                                        <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                                            {/* Replace with actual images */}
                                            <img
                                                src={advisor.imgSrc}
                                                alt={`${advisor.name}, ${advisor.title}`}
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="text-secondary font-bold text-xl">{advisor.name}</h3>
                                        <p className="text-[#6942fe] font-medium text-sm mb-2">{advisor.title}</p>
                                        <p className="text-dark-text text-sm mb-4">{advisor.bio}</p>

                                        {/* Institution logos for advisors */}
                                        <div className="flex flex-wrap gap-2 mt-4">
                                            {advisor.institutions.map((institution, i) => (
                                                <div
                                                    key={i}
                                                    className="w-16 h-16 bg-white rounded-md flex items-center justify-center p-1 shadow-sm"
                                                    title={institution.name}
                                                >
                                                    {institution.logo ? (
                                                        <img
                                                            src={institution.logo}
                                                            alt={institution.name}
                                                            className="max-w-full max-h-full object-contain"
                                                        />
                                                    ) : (
                                                        <span className="text-xs text-gray-400">
                                                            {institution.name.split(' ').map(word => word[0]).join('')}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Join our team section */}
                    <div className="mt-20 flex flex-col items-end text-right opacity-0 transform -translate-x-4"
                        style={{
                            animation: 'slideInLeft 0.8s ease-out forwards',
                            animationDelay: '1400ms',
                            animationFillMode: 'forwards'
                        }}>
                        <h3 className="text-secondary font-bold text-2xl sm:text-3xl uppercase tracking-widest transition-all duration-300 hover:tracking-wider">
                            Join Our Team
                        </h3>
                        <p className="text-dark-text mt-2 max-w-md ml-auto">
                            We're looking for talented individuals passionate about building a sustainable future with CO₂ transformation technology.
                        </p>
                        <Link
                            to="/jobs"
                            className="mt-6 inline-block px-6 py-3 bg-gradient-to-r from-[#6942fe] to-[#33aa63] text-white font-semibold rounded-md hover:scale-105 transition-all duration-300"
                        >
                            View Open Positions
                        </Link>
                    </div>
                </div>
            </div>

            {/* Gradient line at bottom with hover effect */}
            <div className="absolute bottom-16 right-16 hover-expand cursor-pointer transition-all duration-300 opacity-0"
                style={{
                    animation: 'fadeIn 1s ease-out forwards',
                    animationDelay: '1400ms',
                    animationFillMode: 'forwards'
                }}>
                <div className="h-1.5 w-64 gradient-line transform -rotate-90 origin-right"></div>
            </div>
        </div>
    );
};

export default TeamPage;