import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import JobsPage from './pages/JobsPage';
import JobDetailPage from './pages/JobDetailPage';
import TeamPage from './pages/TeamPage';
import AboutPage from './pages/AboutPage';

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check for the route query parameter on initial load
        const queryParams = new URLSearchParams(location.search);
        const route = queryParams.get('route');

        if (route) {
            // Clean up the URL by removing the query parameter
            window.history.replaceState({}, document.title, route);
            // Navigate to the correct route
            navigate(route, { replace: true });
        }
    }, [navigate, location.search]);

    return (
        <Routes>
            {/* Define your routes here */}
            <Route path="/" element={<ContactPage />} />
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="/apply/:id" element={<JobDetailPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/about" element={<AboutPage />} />
        </Routes>
    );
}

export default function Root() {
    return (
        <Router>
            <App />
        </Router>
    );
}