import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jobListings } from '../data/jobListings';
import BackgroundShapes from '../components/BackgroundShapes';

const JobsPage = () => {
    const navigate = useNavigate();

    // Get current time in format HH:MM:SS
    const [time, setTime] = useState(() => {
        const now = new Date();
        return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    });

    // Update time every second
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setTime(`${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#f8f9fc] to-[#f4f6f9]">
            <BackgroundShapes />

            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-10">
                {/* Top header with Jobs and Time */}
                <div className="flex items-center mb-16">
                    <Link
                        to="/"
                        className="text-dark-text hover:text-[#8b6afe] text-2xl uppercase tracking-widest transition-colors duration-300 cursor-pointer opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '100ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        Home
                    </Link>

                    {/* Horizontal line that spans the space between Home and Time */}
                    <div
                        className="flex-grow h-px bg-dark-text/20 mx-4 md:mx-8 opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '150ms',
                            animationFillMode: 'forwards'
                        }}
                    ></div>

                    <p
                        className="text-dark-text text-xl sm:text-2xl uppercase tracking-widest time-pulse opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '200ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        {time}
                    </p>
                </div>

                {/* Main content */}
                <div className="mt-8 md:mt-16">
                    {/* Page title */}
                    <div
                        className="mb-12 opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '300ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        <h1 className="poppins-bold text-dark-text text-4xl sm:text-5xl">
                            Join Our Team
                        </h1>
                        <div className="h-1 w-24 gradient-line mt-4"></div>
                        <p className="text-dark-text text-lg md:text-xl mt-6 whitespace-nowrap overflow-hidden text-ellipsis">
                            Help us transform CO₂ into valuable products and build a more sustainable future.
                        </p>
                    </div>

                    {/* Job listings */}
                    <div className="space-y-8 mt-12">
                        {jobListings.map((job, index) => (
                            <div
                                key={job.id}
                                className={`${job.isOpenApplication ? 'bg-gradient-to-r from-[#f8f9fc] to-[#eef2ff] border-l-4 border-[#6942fe]' : 'bg-white'} rounded-lg shadow-md p-6 transform hover:scale-[1.01] transition-all duration-300 opacity-0`}
                                style={{
                                    animation: 'fadeIn 0.8s ease-out forwards',
                                    animationDelay: `${400 + index * 200}ms`,
                                    animationFillMode: 'forwards'
                                }}
                            >
                                <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
                                    <h2 className="text-2xl text-secondary font-bold">{job.title}</h2>
                                    <div className="mt-2 md:mt-0 flex items-center">
                                        <span className="inline-block px-3 py-1 bg-[#f0f4f8] text-[#5a5a5a] text-sm rounded-full">
                                            {job.location}
                                        </span>
                                        {job.paid && (
                                            <span className="inline-flex items-center px-3 py-1 bg-[#eefbf0] text-green-600 text-sm rounded-full">
                                                <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                Paid
                                            </span>
                                        )}
                                        <span className={`ml-2 ${job.isOpenApplication ? 'text-green-600' : 'text-red-500'} text-sm`}>
                                            {job.isOpenApplication ? 'Always Open' : `Deadline: ${job.deadline}`}
                                        </span>
                                    </div>
                                </div>

                                <p className="text-dark-text mb-4">{job.term}</p>
                                <p className="text-dark-text mb-6">{job.intro}</p>

                                <div className="flex flex-wrap gap-2 mb-6">
                                    {job.desiredMajors.map((major, i) => (
                                        <span key={i} className="inline-block px-3 py-1 bg-[#eef2ff] text-[#6942fe] text-sm rounded-full">
                                            {major}
                                        </span>
                                    ))}
                                </div>

                                {job.isOpenApplication ? (
                                    <a
                                        href="mailto:hello@kaiolabs.com?subject=Open Application"
                                        className="inline-block px-6 py-3 bg-[#6942fe] text-white font-semibold rounded-md hover:opacity-90 transition-opacity"
                                    >
                                        Submit Open Application
                                    </a>
                                ) : job.isOpen ? (
                                    <button
                                        onClick={() => navigate(`/apply/${job.id}`)}
                                        className="inline-block px-6 py-3 bg-gradient-to-r from-[#6942fe] to-[#33aa63] text-white font-semibold rounded-md hover:opacity-90 transition-opacity"
                                    >
                                        View Details & Apply
                                    </button>
                                ) : (
                                    <button
                                        disabled
                                        className="inline-block px-6 py-3 bg-gray-400 text-white font-semibold rounded-md cursor-not-allowed"
                                    >
                                        Position Filled
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Gradient line at bottom */}
            <div
                className="absolute bottom-16 right-16 h-1.5 w-64 gradient-line transform -rotate-90 origin-right opacity-0"
                style={{
                    animation: 'fadeIn 1s ease-out forwards',
                    animationDelay: '1000ms',
                    animationFillMode: 'forwards'
                }}
            ></div>
        </div>
    );
};

export default JobsPage;