import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BackgroundShapes from '../components/BackgroundShapes';

const ContactPage = () => {
    // Get current time in format HH:MM:SS
    const [time, setTime] = useState(() => {
        const now = new Date();
        return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    });

    // Update time every second
    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setTime(`${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#f8f9fc] to-[#f4f6f9]">
            <BackgroundShapes />

            <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 relative z-10">
                {/* Top header with Contact and Time */}
                <div className="flex items-center mb-16">
                    <a
                        href="mailto:will@kaiolabs.com"
                        className="text-dark-text hover:text-[#8b6afe] text-2xl uppercase tracking-widest transition-colors duration-300 cursor-pointer opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '100ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        Contact
                    </a>

                    {/* Horizontal line that spans the space between Contact and Time */}
                    <div
                        className="flex-grow h-px bg-dark-text/20 mx-4 md:mx-8 opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '150ms',
                            animationFillMode: 'forwards'
                        }}
                    ></div>

                    <p
                        className="text-dark-text text-xl sm:text-2xl uppercase tracking-widest time-pulse opacity-0"
                        style={{
                            animation: 'fadeIn 0.8s ease-out forwards',
                            animationDelay: '200ms',
                            animationFillMode: 'forwards'
                        }}
                    >
                        {time}
                    </p>
                </div>

                {/* Main content */}
                <div className="mt-16 md:mt-32">
                    {/* kaiolabs heading with tagline below */}
                    <div className="mb-10">
                        <h1 className="poppins-bold text-dark-text text-6xl sm:text-8xl hover:text-secondary transition-colors duration-300 opacity-0 transform translate-x-4"
                            style={{
                                animation: 'slideInRight 0.8s ease-out forwards',
                                animationDelay: '300ms',
                                animationFillMode: 'forwards'
                            }}>
                            kaıolabs
                        </h1>
                        <p className="text-dark-text italic text-lg md:text-xl mt-4 max-w-2xl opacity-0 transform translate-y-4"
                            style={{
                                animation: 'fadeIn 1s ease-out forwards',
                                animationDelay: '800ms',
                                animationFillMode: 'forwards'
                            }}>
                            Transforming CO₂ into tomorrow's fuels and chemicals
                        </p>
                    </div>

                    {/* "From CO₂ to value" heading */}
                    <div className="mt-16 flex flex-col items-end text-right opacity-0 transform -translate-x-4"
                        style={{
                            animation: 'slideInLeft 0.8s ease-out forwards',
                            animationDelay: '600ms',
                            animationFillMode: 'forwards'
                        }}>
                        <h3 className="text-secondary font-bold text-2xl sm:text-3xl uppercase tracking-widest transition-all duration-300 hover:tracking-wider">
                            From CO₂ to value
                        </h3>

                        {/* New "Join Our Team" button */}
                        <Link
                            to="/jobs"
                            className="mt-6 inline-block px-6 py-3 bg-gradient-to-r from-[#6942fe] to-[#33aa63] text-white font-semibold rounded-md hover:scale-105 transition-all duration-300"
                        >
                            Join Our Team
                        </Link>
                    </div>
                </div>
            </div>

            {/* Gradient line at bottom with hover effect */}
            <div className="absolute bottom-16 right-16 hover-expand cursor-pointer transition-all duration-300 opacity-0"
                style={{
                    animation: 'fadeIn 1s ease-out forwards',
                    animationDelay: '1000ms',
                    animationFillMode: 'forwards'
                }}>
                <div className="h-1.5 w-64 gradient-line transform -rotate-90 origin-right"></div>
            </div>
        </div>
    );
};

export default ContactPage;